import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiPrefixHeremapsAutosuggestInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!/^(http|https):/i.test(request.url)) {
            request = request.clone({ url: environment.serverUrl + 'api/here-maps/proxy/autosuggest/autosuggest' + request.url });
            // request = request.clone({ url: environment.integrations.hereMaps.autosuggest.url + request.url });
        }
        return next.handle(request);
    }
}
